html,
body,
#root {
  background-color: #000;
  color: #fff;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #1e1e1e;
}

.main-container {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: auto;
  padding: 0 15px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #2a2a2a;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 10px;
  border: 2px solid #1e1e1e;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb {
  transition: background-color 0.3s ease;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #444 #2a2a2a;
}

.main-container::-webkit-scrollbar {
  height: 8px;
}

@media (min-width: 500px) {
  .app-container {
    height: 100%;
  }
}
