.guestHome-container {
  width: 100%;
  max-width: 1400px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.guestHome-image {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  color: white;
}

.guestHome-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../assets/alcohol-images/alcohol-extended.jpg") center top
    no-repeat;
  background-size: cover;
  opacity: 0.4;
  z-index: 0;
}

.welcome-message {
  margin-top: 20vh;
  text-align: center;
  font-family: "Cinzel", serif;
  z-index: 1;
  flex-grow: 1;
}

.welcome-message h1 {
  font-size: 17px;
  letter-spacing: 2px;
  padding-bottom: 20px;
  margin: 0;
}

.welcome-message .logo {
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 50px;
}

.welcome-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
  z-index: 1;
}

.main-register-button {
  margin: 0 auto;
  width: 80%;
  padding: 15px;
  border: 1px solid #5c2a25;
  border-radius: 10px;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.main-login-button {
  margin: 8px auto;
  width: 80%;
  padding: 15px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(
    185deg,
    #9a463d,
    #8b3f37,
    #7b3831,
    #6c312b,
    #5c2a25,
    #4d231f,
    #3e1c18,
    #2e1512,
    #1f0e0c,
    #0f0706
  );
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.main-login-button:hover {
  background: linear-gradient(
    185deg,
    #a04b42,
    #93443b,
    #843d34,
    #75362e,
    #663027,
    #572a21,
    #48231a,
    #391d13,
    #2a160d,
    #1b0f07
  );
}

.main-login-button:active {
  background: linear-gradient(
    185deg,
    #8e413a,
    #7f3a33,
    #70332c,
    #612c25,
    #52251e,
    #431f17,
    #341810,
    #251209,
    #160b03,
    #070401
  );
}

.privacy-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  color: #808080;
  font-size: 12px;
  width: 80%;
  text-align: center;
}

.privacy-links a,
.remember-me a {
  font-weight: 600;
  color: #808080;
  text-decoration: underline;
}

.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@keyframes smoothPullup {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

.login-pullup {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  background-color: #121212;
  z-index: 11;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px 20px 0 0;
}

.login-pullup.visible {
  animation: smoothPullup 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}

.login-form {
  display: flex;
  flex-direction: column;
  height: 60vh;
}

.login-form .input-password {
  border-top: none;
  border-radius: 0;
}

.login-form input {
  padding: 20px;
  border: none;
  border-radius: 20px 20px 0 0;
  background: transparent;
  text-align: center;
  color: #fff;
  border-top: 1px solid #2f2f2f;
  border-bottom: 1px solid #2f2f2f;
}

.login-form input::placeholder {
  color: #ccc;
  font-size: 16px;
  opacity: 0.75;
  letter-spacing: 1px;
}

.login-form input:focus {
  border: 1px solid #808080;
}

.login-form button {
  margin: 0 auto;
  width: 80%;
  padding: 15px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(
    185deg,
    #9a463d,
    #8b3f37,
    #7b3831,
    #6c312b,
    #5c2a25,
    #4d231f,
    #3e1c18,
    #2e1512,
    #1f0e0c,
    #0f0706
  );
  color: white;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-top: 20px;
}

.login-form button:hover {
  background: linear-gradient(
    185deg,
    #a04b42,
    #93443b,
    #843d34,
    #75362e,
    #663027,
    #572a21,
    #48231a,
    #391d13,
    #2a160d,
    #1b0f07
  );
}

.login-form button:active {
  background: linear-gradient(
    185deg,
    #8e413a,
    #7f3a33,
    #70332c,
    #612c25,
    #52251e,
    #431f17,
    #341810,
    #251209,
    #160b03,
    #070401
  );
  transform: scale(0.99);
}

.tos-pp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #808080;
  margin: 10px;
  margin-top: 0;
  font-size: 10px;
  width: 100%;
}

.tos-pp .checkbox {
  padding: 0;
  border-radius: 0;
  border: none;
  width: 16px;
  height: 16px;
}

.remember-me {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #808080;
}

.remember-me input[type="checkbox"] {
  margin-right: 8px;
  cursor: pointer;
}

.remember-me label {
  cursor: pointer;
  margin-right: 25px;
}

@media (min-width: 768px) {
  .hidden {
    display: none;
  }

  .guestHome-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }

  .guestHome-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .login-form {
    width: 100%;
    max-width: 500px;
    z-index: 10;
  }

  .main-register-button,
  .main-login-button,
  .privacy-links {
    width: 50%;
  }

  .login-form {
    height: 60vh;
  }

  .login-form input {
    padding: 20px;
  }

  .login-form button {
    padding: 20px;
    width: 50%;
  }

  .tos-pp {
    font-size: 10px;
  }
}

@media (min-width: 1024px) {
  .welcome-bottom {
    width: 80%;
  }

  .tos-pp label {
    font-size: 12px;
  }
}
