.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.phone-container {
  width: 80%;
  max-width: 300px;
  margin-bottom: 15px;
}

.phone-button {
  background-color: transparent !important;
}

.react-tel-input .form-control {
  background: rgba(255, 255, 255, 0.005) !important;
  border: 1px solid #808080 !important;
  border-radius: 5px !important;
  backdrop-filter: blur(10px) !important;
  color: #fff;
  padding: 23px;
  /* padding-right: 46px !important; */
  font-size: 16px !important;
  width: 100% !important;
  box-sizing: border-box;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  display: flex;
  align-items: center;
}

.react-tel-input .country-list {
  background-color: rgba(0, 0, 0, 0.9) !important;
  color: #808080 !important;
}

.react-tel-input .country-list .country {
  padding: 10px;
}

.react-tel-input .country-list .country:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.react-tel-input .form-control:focus {
  border-color: #ccc !important;
}

.login-input,
.main-button,
.other-button {
  width: 80%;
  max-width: 300px;
  padding: 15px;
  margin: 0;
  margin-bottom: 15px;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
}

.login-input {
  background: rgba(255, 255, 255, 0.005);
  border: 1px solid #808080;
  border-radius: 5px;
  backdrop-filter: blur(10px);
  color: white;
  padding: 15px;
  font-size: 16px;
}

.main-button {
  background: linear-gradient(
    185deg,
    #9a463d,
    #8b3f37,
    #7b3831,
    #6c312b,
    #5c2a25,
    #4d231f,
    #3e1c18,
    #2e1512,
    #1f0e0c,
    #0f0706
  );
  color: white;
  border: none;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-bottom: 8px;
}

.main-button:hover {
  background: linear-gradient(
    185deg,
    #a04b42,
    #93443b,
    #843d34,
    #75362e,
    #663027,
    #572a21,
    #48231a,
    #391d13,
    #2a160d,
    #1b0f07
  );
}

.main-button:active {
  background: linear-gradient(
    185deg,
    #8e413a,
    #7f3a33,
    #70332c,
    #612c25,
    #52251e,
    #431f17,
    #341810,
    #251209,
    #160b03,
    #070401
  );
  transform: scale(0.99);
}

.other-button {
  padding: 15px;
  border: 1px solid #5c2a25;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.error-message {
  color: red;
  font-weight: 700;
  margin-top: 0;
}
